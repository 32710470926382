<template>
  <default-layout :breadcrumbs="breadcrumbs">
    <div v-if="project">
      <banner
        :project="project"
        :store="store"
        :imageIndex="imageIndex"
        :normalGalleries="normalGalleries"
      ></banner>
      <div class="project--container">
        <div class="left--col">
          <project-header :project="project" />
          <attachment :project="project" />
          <detail :project="project" :unitTypes="unitTypes" />
          <!--        <area-detail :project="project" />-->
          <unit-types
            :unitTypes="unitTypes"
            v-if="project.listing_type === listingTypeEnum.PRIMARY"
          />
          <facilities
            :facilities="project.facilities"
            v-if="project.listing_type === listingTypeEnum.SECONDARY"
          />
          <description
            :description="
              project.listing_type === listingTypeEnum.SECONDARY
                ? project.description
                : project.project_desc
            "
            :listingType="project.listing_type"
          />
          <!--        <operational-hour :project="project" />-->
          <!--        <facility :project="project"></facility>-->
          <!--        <amenity :amenities="project.amenities" />-->
          <client-only>
            <project-map :project="project" />
          </client-only>
          <mortgage-calculator :project="project"></mortgage-calculator>
          <nearby-places
            :nearbyPlaces="project.nearby_places"
            v-if="project.listing_type === listingTypeEnum.PRIMARY"
          />
          <similar :uuid="project.uuid" :project="project" />
        </div>
        <div class="right--col">
          <action
            :project="project"
            type="project"
            :agentUuid="agentUuid"
            :listingUuid="project.uuid"
          />
          <!--        <contact :project="project" />-->
        </div>
      </div>
    </div>
    <script v-html="jsonldBreadcrumb" type="application/ld+json"></script>
    <script v-html="jsonld" type="application/ld+json"></script>
    <script v-html="unitTypeJsonld" type="application/ld+json"></script>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
import Banner from '@/components/project-detail/banner';
import ProjectHeader from '@/components/project-detail/header';
// import OperationalHour from '@/components/project-detail/operational-hour';
import Attachment from '@/components/project-detail/attachment';
import Detail from '@/components/project-detail/detail';
// import AreaDetail from '@/components/project-detail/area-detail';
import Description from '@/components/project-detail/description';
// import Facility from '@/components/project-detail/facility';
// import Amenity from '@/components/project-detail/amenity';
import ProjectMap from '@/components/project-detail/map';
import Action from '@/components/project-detail/action';
import Similar from '@/components/project-detail/similar';
import UnitTypes from '@/components/project-detail/unit-types';
import NearbyPlaces from '@/components/project-detail/nearby-places';
import Facilities from '@/components/project-detail/facilities';
import MortgageCalculator from '@/components/project-detail/mortgage-calculator';

import HelperMixin from '@/mixins/helpers';
import LodashMixin from '@/mixins/lodash';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [HelperMixin, LodashMixin],
  components: {
    DefaultLayout,
    // OperationalHour,
    Banner,
    ProjectHeader,
    Attachment,
    Detail,
    // AreaDetail,
    Description,
    // Facility,
    // Amenity,
    ProjectMap,
    Action,
    Similar,
    NearbyPlaces,
    UnitTypes,
    Facilities,
    MortgageCalculator,
  },
  data: () => ({
    store: 'v2/project',
    hasExited: false,
  }),
  computed: {
    ...mapState({
      project: (state) => state.v2.project.project,
      agentUuid: (state) => state.v2.project.agentUuid,
      isLoading: (state) => state.global.isLoading,
      isLoggedIn: (state) => state.global.loggedIn,
      imageIndex: (state) => state.v2.project.imageIndex,
      listingTypeEnum: (state) => state.v2.masters.listingTypeEnum,
      user: (state) => state.global.user,
    }),
    ...mapGetters({
      normalGalleries: 'v2/project/galleries',
      unitTypes: 'v2/project/unitTypes',
    }),
    typeString() {
      return this.$t('general.buy');
    },
    propertyTypeString() {
      if (this.project && this.project.property_type) {
        return this.project.property_type;
      } else {
        return '';
      }
    },
    projectTitle() {
      if (this.project) {
        return this.project.listing_type === this.listingTypeEnum.SECONDARY
          ? this.project.title
          : this.project.project_name;
      } else {
        return '';
      }
    },
    breadcrumbs() {
      if (!this.project) return [];
      return [
        {
          text: this.$t('breadcrumb.homepage'),
          to: '/',
        },
        {
          text: `${this.typeString} ${this.propertyTypeString}`,
          to: `/cari/${this.propertyTypeString.toLowerCase()}`,
        },
        {
          text: this.projectTitle,
          to: this.project.links ? this.project.links.detail : this.$router.currentRoute.path,
        },
      ];
    },
    jsonldBreadcrumb() {
      if (!this.project) return {};
      let baseUrl = this.$store.state.global.baseUrl;
      return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            name: this.$t('breadcrumb.homepage'),
            item: baseUrl + '/',
          },
          {
            '@type': 'ListItem',
            position: 2,
            name: `${this.typeString} ${this.propertyTypeString}`,
            item: baseUrl + `/cari/${this.propertyTypeString.toLowerCase()}`,
          },
          {
            '@type': 'ListItem',
            position: 3,
            name: this.projectTitle,
            item: this.project.links
              ? baseUrl + this.project.links.detail
              : baseUrl + this.$router.currentRoute.path,
          },
        ],
      };
    },
    jsonld() {
      if (!this.project) return {};
      let photos = this.project ? this.project.photos : null;
      if (photos) {
        if (photos.data) {
          photos = photos.data;
        }
      } else {
        photos = [];
      }

      if (this.project.listing_type === 'SECONDARY') {
        return this.getListingJsonld({
          baseUrl: this.$store.state.global.baseUrl,
          path:
            this.project && this.project.links
              ? this.project.links.detail
              : this.$router.currentRoute.path,
          listing: this.project,
          galleries: photos,
        });
      } else {
        return this.getProjectJsonld({
          baseUrl: this.$store.state.global.baseUrl,
          path:
            this.project && this.project.links
              ? this.project.links.detail
              : this.$router.currentRoute.path,
          project: this.project,
          galleries: photos,
        });
      }
    },
    unitTypeJsonld() {
      if (!this.project) return {};
      let baseUrl = this.$store.state.global.baseUrl;
      let path =
        this.project && this.project.links
          ? this.project.links.detail
          : this.$router.currentRoute.path;
      const category = this.$route.params.category;
      const propertyType =
        this.project && this.project.property_type
          ? this.project.property_type.toLowerCase()
          : 'properti';
      let projectName = '';
      let title = '';
      const splitCategory = category.split('-');
      if (
        splitCategory[0] === 'properti' ||
        splitCategory[splitCategory.length - 1] === 'secondary'
      ) {
        projectName = this.project.title;
        title = projectName;
      } else if (splitCategory[splitCategory.length - 1] === 'baru') {
        projectName = this.project.project_name;
        title = 'Dijual ' + propertyType + ' ' + projectName;
      }

      let location = '';
      if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
        location = this.project.area + ', ' + this.project.city;
      } else {
        location = this.project.district + ', ' + this.project.city;
      }

      let ownerName = '';
      if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
        ownerName = this.project.owner.name;
      } else {
        ownerName = this.project.developer_name;
      }

      let description =
        'Dijual ' +
        propertyType +
        ' ' +
        projectName +
        ' di ' +
        location +
        '. Cari dan beli ' +
        propertyType +
        ' oleh ' +
        ownerName +
        (this.project.project_desc ? ' | ' + this.project.project_desc : '');

      let itemList = [],
        i = 1;
      for (let unitType of this.unitTypes) {
        itemList.push({
          '@type': 'ListItem',
          position: i,
          item: this.getUnitTypeJsonld({
            baseUrl: baseUrl,
            path: unitType.links.detail,
            project: this.project,
            unitType: unitType,
            galleries: unitType.photos,
          }),
        });
        i++;
      }

      return itemList.length > 0
        ? {
            '@type': 'RealEstateListing',
            description: description,
            name: title,
            url: baseUrl + path,
            mainEntity: {
              '@type': 'ItemList',
              itemListElement: itemList,
            },
          }
        : {};
    },
  },
  // eslint-disable-next-line no-unused-vars
  async fetch({ store, params, query, route, router, error, redirect }) {
    store.commit('head/RESTORE_DEFAULT_VALUE', route);
    const category = params.category;
    // console.log('fetch query 1:', query);
    // console.log('fetch query 2:', route);
    // console.log('fetch query 3:', router);
    // console.log('fetch query 4:', query.agent_uuid);
    if (query.agent_uuid) {
      store.commit('v2/project/set_agent_uuid', query.agent_uuid);
      // router.replace({ query: queries });
    }
    const splitCategory = category.split('-');
    if (
      splitCategory[0] === 'properti' ||
      splitCategory[splitCategory.length - 1] === 'secondary'
    ) {
      let project;
      try {
        project = await store.dispatch('v2/project/getListing', params.hashId);
      } catch (e) {
        error(new Error(e.message), 404);
      }
      if (project && project.links) {
        if (route.path !== project.links.detail) {
          redirect({ path: project.links.detail }, 301);
        }
      }
      if (store.state.global.loggedIn) {
        await store.dispatch('v2/search/getShortlistUuids', {
          listingType: store.state.v2.masters.listingTypeEnum.SECONDARY,
        });
      }
    } else if (splitCategory[splitCategory.length - 1] === 'baru') {
      let project;
      try {
        project = await store.dispatch('v2/project/getProject', params.hashId);
      } catch (e) {
        error(new Error(e.message), 404);
      }
      if (project && project.links) {
        if (route.path !== project.links.detail) {
          redirect({ path: project.links.detail }, 301);
        }
      }
      if (store.state.global.loggedIn) {
        await store.dispatch('v2/search/getShortlistUuids', {
          listingType: store.state.v2.masters.listingTypeEnum.PRIMARY,
        });
      }
    } else {
      error(new Error('LINK LISTING DETAIL TIDAK VALID!'), 404);
    }

    const seoTitle = store.getters['v2/project/seoTitle'];
    const seoDesc = store.getters['v2/project/seoDesc'];
    const project = store.state.v2.project.project;
    const baseUrl = store.state.global.baseUrl;
    const primaryPhoto = store.getters['v2/project/primaryPhoto'];

    let photoUrl = '';
    if (primaryPhoto && Object.keys(primaryPhoto).length > 0) {
      photoUrl = primaryPhoto.url;
    } else if (project && project.photos && Object.keys(project.photos).length > 0) {
      photoUrl = project.photos[0].url;
    }
    photoUrl = photoUrl.replace('/300', '/1080');

    const head = {
      title: seoTitle,
      meta: [
        {
          hid: 'og-url',
          property: 'og:url',
          content: baseUrl + (project.links ? project.links.detail : ''),
        },
        { hid: 'og-type', property: 'og:type', content: 'article' },
        { hid: 'og-title', property: 'og:title', content: seoTitle },
        {
          hid: 'og-description',
          property: 'og:description',
          content: seoDesc,
        },
        {
          hid: 'description',
          name: 'description',
          content: seoDesc,
        },
        { hid: 'og-site-name', property: 'og:site_name', content: 'BeliRumah' },
        {
          hid: 'og-image',
          property: 'og:image',
          content: photoUrl,
        },
        { hid: 'og-image-width', property: 'og:image:width', content: '450' },
        { hid: 'og-image-height', property: 'og:image:height', content: '450' },
      ],
      link: [
        {
          rel: 'canonical',
          href: baseUrl + (project.links ? project.links.detail : ''),
          id: 'canonical',
        },
      ],
    };
    store.commit('head/SET_CONTENT', head);
  },
  beforeDestroy() {
    this.hasExited = true;
  },
  mounted() {
    this.debounceCheckVisitor = this.debounce(this.checkVisitor, 3000);
    this.debounceCheckVisitor();

    const catalogAgentUuid = sessionStorage.getItem('CTGAID');
    if (!catalogAgentUuid) {
      sessionStorage.setItem('CTGAID', this.$store.state.v2.project.agentUuid);
    } else if (catalogAgentUuid !== 'null') {
      this.$store.commit('v2/project/set_agent_uuid', catalogAgentUuid);
    } else {
      this.$store.commit('v2/project/set_agent_uuid', null);
    }

    if (this.$store.state.v2.project.agentUuid) {
      var objectToQueryString = function objectToQueryString(obj) {
        var str = [];
        for (var p in obj)
          if (obj.hasOwnProperty(p)) {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
          }
        return str.join('&');
      };
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      delete queries.agent_uuid;

      history.replaceState({}, null, this.$route.path + '?' + objectToQueryString(queries));
    }
  },
  methods: {
    async checkVisitor() {
      // Pengecekan apakah login atau tidak di-take out dulu untuk reduce friction

      // if (!this.hasExited) {
      //   await this.$store.dispatch('global/fetch');
      //   if (!this.isLoggedIn) {
      //     this.$modal.show('modal-login', { dismissable: false });
      //   } else if (this.user && !this.user.phone_number) {
      //     this.$modal.show('modal-phone', { dismissable: false, from: 'detail' });
      //   } else {
      if (this.project) {
        let payload = {};
        if (this.project.listing_type === this.listingTypeEnum.PRIMARY) {
          payload.projectUuid = this.project.uuid;
        } else if (this.project.listing_type === this.listingTypeEnum.SECONDARY) {
          payload.listingUuid = this.project.uuid;
        }
        await this.$store.dispatch('visitor/visit', payload);
      }
      //   }
      // }
    },
  },
};
</script>
